<template>
  <div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-form-group
      v-if="user.role === 'admin'"
      label="Depot"
    >
      <validation-provider
        #default="{ errors }"
        name="Depot"
      >
        <v-select
          v-model="repository"
          :clearable="false"
          placeholder="Depots"
          label="name"
          :options="repositories"
          @input="getDepositOrder"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Référence de colis"
      label-for="vi-first-name"
    >
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          ref="select"
          v-model="ref"
          placeholder="Scanner un colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <!-- table -->
    <div class="d-flex justify-content-between">
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <b-card>
        <h4>colis au depot : {{ orders.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="orders"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm ,
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="CheckSquareIcon"
                  class="cursor-pointer mr-1"
                  size="16"
                  color="green"
                  @click="addItem(props.row)"
                />
                <b-tooltip
                  title="Ajouter"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card class="ml-1">
        <h4>colis dans feuille de route : {{ selected.length }}</h4>
        <vue-good-table
          :columns="columns"
          :rows="selected"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'id'">
              <span> {{ props.row.id }}</span><br>
              <span
                v-if="props.row.is_an_exchange"
                style="color:red ; font-weight: bold;"
              > Echange </span>
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <feather-icon
                  :id="`invoice-row-${props.row.id}-show-icon`"
                  icon="DeleteIcon"
                  color="red"
                  class="cursor-pointer mr-1"
                  size="16"
                  @click="resetItem(props.row)"
                />
                <b-tooltip
                  title="Annuler"
                  class="cursor-pointer"
                  :target="`invoice-row-${props.row.id}-show-icon`"
                />

              </span>
            </span>

            <!-- Column: Common -->

            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

        </vue-good-table>
        <b-form-group
          label="Livreur"
        >
          <validation-provider
            #default="{ errors }"
            name="Livreur"
          >
            <v-select
              v-model="livreur"
              :clearable="false"
              placeholder="Livreur"
              label="username"
              :options="drivers"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Voiture"
        >
          <validation-provider
            #default="{ errors }"
            name="Voiture"
          >
            <v-select
              v-model="livreur.car"
              :clearable="false"
              placeholder="Voiture"
              label="serial_number"
              :options="cars"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group class="d-flex justify-content-end">
          <b-button
            variant="gradient-primary"
            class="ml-1 d-inline-block"
            :class="{ 'disabled-cursor': isLoading || !isRoadmapValid }"
            :disabled="isLoading || !isRoadmapValid"
            @click="addRoadMap"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Enregistrer</span>
          </b-button>
        </b-form-group>
      </b-card>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip, BSpinner,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider } from 'vee-validate'
import zeroTentative from '@/assets/audio/0tentative.mp3'
import oneTentative from '@/assets/audio/1tentative.mp3'
import twoTentative from '@/assets/audio/2tentative.mp3'
import threeTentative from '@/assets/audio/3tentative.mp3'
import fourTentative from '@/assets/audio/4tentative.mp3'
import fiveTentative from '@/assets/audio/5tentative.mp3'
import isAnExchange from '@/assets/audio/exchange.mp3'
import ok from '@/assets/audio/ok.mp3'
import errorVoice from '@/assets/audio/error.mp3'
import alreadyScanned from '@/assets/audio/colis-deja-scanne.mp3'

export default {
  name: 'CreateRoadmap',
  components: {
    BButton,
    ValidationProvider,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BTooltip,
    vSelect,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      drivers: [],
      livreur: {},
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'BL',
          field: 'id',
        },
        {
          label: 'Client',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher client',
          },
        },
        {
          label: 'Fournisseur',
          field: 'supplier.username',
          filterOptions: {
            enabled: true,
            placeholder: 'Fournisseur',
          },
        },
        {
          label: 'Gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'Délégation',
          field: 'delegation',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher délégation',
          },
        },
        {
          label: 'Adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
      ],
      role: '',
      orders: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      cars: [],
      car: {},
      repository: {},
      repositories: [],
      zeroTentative,
      oneTentative,
      twoTentative,
      threeTentative,
      fourTentative,
      fiveTentative,
      isAnExchange,
      ok,
      errorVoice,
      alreadyScanned,
    }
  },
  computed: {
    isRoadmapValid() {
      return (
        this.livreur !== ''
        && this.selected.length > 0
      )
    },
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'manager') {
      this.getDepositOrder()
    }
    this.getRepositories()
    this.getDeliveryMans()
    this.getCars()
  },
  mounted() {
    this.$refs.select.focus()
  },
  methods: {
    async checkOrder() {
      const existingOrder = this.selected.find(item => {
        if (item.supplier.id === 504) {
          return item.bestway_barcode === Number(this.ref) || item.id === Number(this.ref)
        }
        return item.id === Number(this.ref)
      })

      if (existingOrder) {
        this.withTitle('Colis déjà scanné sur la liste sélectionnée')
        this.playAudio(alreadyScanned)
      } else {
        const searchedItem = this.orders.find(element => {
          if (element.supplier.id === 504) {
            return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
          }
          return element.id === Number(this.ref)
        })

        if (searchedItem) {
          if (searchedItem.is_an_exchange) {
            await this.playAudio(isAnExchange)
            await this.delay(500)
          }
          this.addItem(searchedItem).then(() => { this.ref = '' })
          if (searchedItem.return_number === 0) {
            this.playAudio(zeroTentative)
          } else if (searchedItem.return_number === 1) {
            this.playAudio(oneTentative)
          } else if (searchedItem.return_number === 2) {
            this.playAudio(twoTentative)
          } else if (searchedItem.return_number === 3) {
            this.playAudio(threeTentative)
          } else if (searchedItem.return_number === 4) {
            this.playAudio(fourTentative)
          } else if (searchedItem.return_number === 5) {
            this.playAudio(fiveTentative)
          }
        } else {
          this.withTitle('Colis non exist')
          this.playAudio(errorVoice)
        }
      }
    },
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    async delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })
      this.orders.splice(i, 1)
    },

    async resetItem(item) {
      this.orders.push(item)
      const i = this.selected.findIndex(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === item.bestway_barcode
        }
        return element.id === item.id
      })

      this.selected.splice(i, 1)
    },

    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    async getCars() {
      const { data } = await axios.get('/api/cars/')
      this.cars = data
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    async addRoadMap() {
      this.isLoading = true
      let repository = null
      if (storeAuth.state.user.role === 'admin') {
        repository = this.repository.id
      } else {
        repository = storeAuth.state.user.repository
      }
      const covertedOrders = this.selected.map(order => order.id)

      const obj = {
        orders: covertedOrders,
        delivery_man_id: this.livreur.id,
        repository_id: repository,
      }
      try {
        // :TODO: bla blba bla
        const { data } = await axios.post('/api/roadmaps/create-roadmap/', obj)
        this.showToast('success', 'top-center', 'Feuille de route ajouté avec succés')
        this.$router.push(`/roadmaps/show/${data}`)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    async getDeliveryMans() {
      this.isLoading = true
      const { data } = await axios.get('api/auth/users/drivers-for-roadmap/', { params: { repository_id: this.repository.id } })
      this.drivers = data
      this.isLoading = false
    },
    async getDepositOrder() {
      this.isLoading = true
      this.livreur = {}
      this.getDeliveryMans()
      this.selected = []
      let repository = null
      if (storeAuth.state.user.role === 'admin') {
        repository = this.repository.id
      } else {
        repository = storeAuth.state.user.repository
      }
      await axios.get(`/api/orders/get-status-deposit/${repository}`).then(response => {
        this.orders = response.data
        this.isLoading = false
      })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },

  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
